.privacy-policy-container {
    background-color: #f3f3f3;
    padding: 20px;
  }
  
  .privacy-policy {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 200px; /* Adjust size as needed */
    height: auto;
  }
  
  .privacy-policy-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .privacy-policy-content > div {
    padding: 20px;
    background-color: #fff;
  }
  